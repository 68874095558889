/********************Custom Product init******************** */

function openCustomModal(product_id) {
    /**
     * Mobile check if size selected
     */
    if (document.body.classList.contains('mobile')) {

        var value = getInputVal.bind(document.getElementById('item_add_form'));
        var size = value('itm_size', 'none');
        var head_offset = (window.innerWidth * 0.2921875);
        var form_offset;

        if ($('.prod_page_bot', '#content_product').length > 0) {
            form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop;
        }

        if (form_offset) {
            scroll_delay = $(window).scrollTop() * 0.8;
            scroll_delay = Math.min(scroll_delay, 400);

            $('html, body').animate({ scrollTop: form_offset - head_offset }, scroll_delay);
        }

        if (!size) {

            document.getElementById('item_add_form').itm_size.classList.add('error');
        } else {

            doRequest(product_id);
        }
    } else {

        doRequest(product_id);
    }
}

var addToBasketPersonalize = false;

function doRequest(product_id) {

    let url = path_relative_root + create_link('ajax_configurator_bystep');

    $.post( url, {"product_id" : product_id}, function( data ) {
        processCustom( JSON.parse(data) );
    });
}

var customData = {};

function initCustomData(modal, res) {
    var customData = {
        'productId': res.product.id,
        'steps': res.steps,
        'pictures': res.product.pictures,
        'width': res.product.width,
        'height': res.product.height,
        'price' : res.steps["1"].products[0].price.replace(".00", "") + "€",
        'text' : "",
        'position' : { "list" : createListPosition(res.product.attributes),
            "index" : 0,
            "parentSelector" : ".list-location",
        },
        'font' : { "list" : createListFont(res.configurator.option_broderie.config.fonts),
            "index" : 0,
            "parentSelector" : ".list-typo",
        },
        'color' : { "list" :  createListColors(res.configurator.option_broderie.config.colors),
            "index" : 0,
            "parentSelector" : ".list-colors",
        },
        'img': {"list": [
                    {'elemHTML' : `<img src="${res.product.pictures.P}" alt="kujten ${res.product.subtitle}">
                    <p class="display-text-custom"></p>`}],
                "parentSelector" : ".img-block",
        },
    }
    modal.querySelector(".product-info .price").innerText = customData.price
    var ListData = [customData.position, customData.font, customData.color, customData.img];
    addDomElem(modal, ListData);
    return customData;
}

function showMessageOnHover() {
    var input = document.querySelector("#text-perso");
    var customizationButton = document.querySelector("#customization-button");
    var errorMessage = document.querySelector("#error-message");

    if (input.value.trim() === '') {
        errorMessage.style.display = 'block';
        customizationButton.disabled = true;
    } else {
        errorMessage.style.display = 'none';
        customizationButton.disabled = false;
    }
}

function processCustom(res) {
    var modal = document.querySelector(".modal-custom-container");
    var customData = initCustomData(modal, res)
    var header = document.querySelector("#site_head_wrap") ? document.querySelector("#site_head_wrap") : document.querySelector(".banner_wrapper");
    var customizationButton = document.querySelector("#customization-button");
    var overlay = document.querySelector("#button-overlay");
    var errorMessage = document.querySelector("#error-message");
    var noCustomizationButton = document.querySelector("#no-customization-button");
    //var headerHeight = header.querySelector(".header_menu_top").getBoundingClientRect().height;
    var imgBlock = modal.querySelector(".img-block");
    var allInputRadio = modal.querySelectorAll('input[type="radio"]');
    var inputTextCustom = modal.querySelector('input.text-perso');
    var imgBlockSize = imgBlock.getBoundingClientRect();


    function handleInput() {
        if (inputTextCustom.value.length >= 1) {
            errorMessage.style.display = 'none';
            customizationButton.disabled = false;
        }
        if (inputTextCustom.value.length >= maxLengthEmbroidery) {
            inputTextCustom.value = inputTextCustom.value.slice(0, maxLengthEmbroidery);
        }
    }

    // Ajoutez un event listener pour l'événement 'input' sur le champ d'input
    inputTextCustom.addEventListener('input', handleInput);

    var actionName = overlay.classList.contains('mobile') ? 'touchstart' : 'mousemove';
    overlay.addEventListener(actionName, showMessageOnHover);

    header.classList.add('modal-active');
    if (document.body.classList.contains('mobile')) {

        $("html, body").animate({scrollTop: 0}, 500);
        modal.classList.add('open');
        $("#shade").addClass("modal-personnalisation")
    } else {

        openMultiShad('modal-personnalisation');
    }

    $(".toggleOption").each(function () {

        $(this).on("click", function () {

            $(this).toggleClass('opened');
        })
    });
    customizationButton.addEventListener("click", function(e) {
        addCustomizationToBasket(customData);
    });

    noCustomizationButton.addEventListener("click", function(e) {
        addToBasketPersonalize = true;
        var btnAddToCartId = ($('#btn_add_cart').length > 0) ? 'btn_add_cart' : 'button_add';
        $("#" + btnAddToCartId).click();
    });

    for (var i = 0; i < allInputRadio.length; i++) {
        allInputRadio[i].addEventListener("click", function(e) {
            updateCustom(modal, customData);
            printCustomText(customData, modal);
        })
    }
    inputTextCustom.addEventListener("input", function(e) {
        updateCustom(modal, customData);
        printCustomText(customData, modal);
    })

    function ft_resizeCustom() {
        resizeCustom(customData)
    }
}

function addDomElem(modal, ListData){
    for (obj of ListData){
        var result = ""
        for (elem of obj.list){
            result += elem.elemHTML
        }
        modal.querySelector(obj.parentSelector).innerHTML = result
    }
}

function closeModal(e) {
    function ft_resizeCustom() {
        resizeCustom(customData)
    }

    var modal = document.querySelector(".modal-custom-container");
    var header = document.querySelector("#site_head_wrap");
    var btnCustom = document.querySelector("#btn_custom");
    var btnClose = document.querySelector(".close-modal-custom");
    var path = e.path || (e.composedPath && e.composedPath());

    if (!path.includes(modal) || path.includes(btnCustom) || path.includes(btnClose)){
        window.removeEventListener("resize", ft_resizeCustom);
        document.removeEventListener("click", closeModal);
        header.classList.remove('modal-active');
        //modal.style.display = 'none';
        closeMultiShad('modal-personnalisation');
        document.querySelector("#modal-personnalisation").classList.remove('open'); // Needed for mobile
    }
}

// resize le div qui contien l'image, avec la fonction CSS max.
//  Cette fonction CSS ne marche pas sur notre version de gulp
function resizeCustom(customData) {
    var modal = document.querySelector(".modal-custom-container");
    var imgBlock = modal.querySelector(".img-block");
    updateCustom(modal, customData);
    var imgBlockSize = {};

    printCustomText(customData, modal);
}

function createListPosition(attributes){
    var result = [];
    for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        var elem = {
            "x" : attribute.position.x,
            'y' : attribute.position.y,
            "textAlign" : attribute.position.textAlign,
            "elemHTML" : `<input type="radio" name="location" id="${attribute.label.replace(" ", "")}"${i === 0 ? " checked" : ""}>
            <label for="${attribute.label.replace(" ", "")}">
            <img src="${attribute.picture}" alt="" class="location">
            <span class="location">${attribute.label}</span>
            </label>`
        };
        result.push(elem);
    }
    return result
}

function createListFont(fonts){
    var result = [];
    var device = document.body.classList.contains('mobile') ? 'mobile' : 'desktop';
    for (let i = 0; i < fonts.length; i++) {
        const font = fonts[i];
        var elem = {
            "font" : font.font,
            "sizes" : font.sizes[device],
            "transform" : font.transform,
            "pattern" : font.pattern,
            "elemHTML" : `<input type="radio" name="typo" id="${font.name.replace(/\s/g,'')}"${i === 0 ? " checked" : ""}>
            <label for="${font.name.replace(/\s/g,'')}">
            <span class="typo">${font.name}</span>
            </label>`
        };
        result.push(elem);
    }
    return result;
}

function createListColors(colors){
    var result = [];
    for (let i = 0; i < colors.length; i++) {
        const color = colors[i];
        var elem = {
            "name" : color.name,
            "code" : color.code,
            "elemHTML" : `<input type="radio" name="color" id="${color.name.replace(" ", "")}"${i === 0 ? " checked" : ""}>
            <label for="${color.name.replace(" ", "")}">
            <div class="color" style="background-color: ${color.code};"></div>
            </label>`
        };
        result.push(elem);
    }
    return result
}

function updateCustom(modal, customData) {
    customData.text = modal.querySelector(".text-input input").value;
    customData.position.index = findIndexInputChecked(".list-location", modal)
    customData.font.index = findIndexInputChecked(".list-typo", modal)
    customData.color.index = findIndexInputChecked(".list-colors", modal)
    return customData
}

function changeFontSize(customData, imgBlock) {
    var pText = imgBlock.querySelector(".display-text-custom");
    pText.style.fontSize = customData.font.list[customData.font.index].sizes;
}

// return la position de l'input selectionné
function findIndexInputChecked(parentSelector, modal) {
    var parent = modal.querySelector(parentSelector);
    var allInput = parent.querySelectorAll("input");

    for (var i = 0; i < allInput.length; i++) {
        if (allInput[i].checked){
            return i;
        }
    }
}

function testPattern(font, textOnImage, customData, inputTextCustom){
    var re = new RegExp(font.pattern);

    if (re.test(customData.text)){
        return customData.text
    } else if (textOnImage.innerText.length && customData.text.length !== 0){
        inputTextCustom.value = textOnImage.innerText
        return textOnImage.innerText
    }else{
        inputTextCustom.value = ""
        return ""
    }
}

function printCustomText(customData, modal) {
    var textOnImage = modal.querySelector("p.display-text-custom");
    var imgBlock = modal.querySelector(".img-block");
    var pColorName = modal.querySelector(".title-and-option .choice");
    var position = customData.position.list[customData.position.index];
    var font = customData.font.list[customData.font.index];
    var color = customData.color.list[customData.color.index];
    var inputTextCustom = modal.querySelector(".text-input input");
    var leftPosCenter = textOnImage.getBoundingClientRect().width / 2;
    var leftPosRight = textOnImage.getBoundingClientRect().width;
    var leftPosDefault = position.x;

    textOnImage.innerText = testPattern(font, textOnImage, customData, inputTextCustom);
    textOnImage.style.fontFamily = font.font;
    textOnImage.style.textAlign = position.textAlign;
    changeFontSize(customData, imgBlock);

    if (position.textAlign === "center")
        //textOnImage.style.left = `calc(${position.x}% - ${textOnImage.getBoundingClientRect().width / 2}px)`;
        textOnImage.style.left = `calc(${leftPosDefault}% - ${leftPosCenter}px)`;
    else if (position.textAlign === "right")
        //textOnImage.style.left = `calc(${position.x}% - ${textOnImage.getBoundingClientRect().width}px)`;
        textOnImage.style.left = `calc(${leftPosDefault}% - ${leftPosRight}px)`;
    else
        textOnImage.style.left = `calc(${leftPosDefault}%)`;

    textOnImage.style.top = `calc(${position.y}%)`;
    textOnImage.style.color = color.code;
    textOnImage.style.textTransform = font.transform;
    textOnImage.dataset.font = font.font;
    pColorName.innerText = color.name;
}

function addCustomizationToBasket(customData) {
    var productId = customData.productId;
    var colorId = ($('#couleurProd').length > 0) ? $('#couleurProd').val() : $('#prod_couleur').val();
    var sizeId = ($('#tailleProd').length > 0) ? $('#tailleProd').val() : $('#prod_taille').val();
    $.ajax({
        url: path_relative_root + create_link("ajax_upd_panier.php"),
        type: "post",
        dataType: 'json',
        data: {
            configurator: true,
            idprod: productId,
            idcouleur: colorId,
            idtaille: sizeId,
            panierid: 0,
            qte: 1,
            configurator_product: false,
            dataTypeReturn: 'json',
            reinit_basket_virtuel_parent_id: true,
            virtual_basket: true,
        },
        success: function (res) {
            try {

                if (res.error == 0) {
                    var x = customData.position.list[customData.position.index].x;
                    var y = customData.position.list[customData.position.index].y;
                    var reel_x = customData.width * (x/100)
                    var reel_y = customData.height * (y/100)
                    var currentProductStep = customData.steps["1"].products[0];
                    var location = ($('input[name=location]:checked + label span.location').length) ? $('input[name=location]:checked + label span.location').html() : '';
                    var fontName = ($('input[name=typo]:checked + label .typo').length) ? $('input[name=typo]:checked + label .typo').html() : '';
                    var transform = customData.font.list[customData.font.index].transform;
                    if (transform == 'lowercase') {
                        var text = customData.text.toLowerCase();
                    } else if (transform == 'uppercase') {
                        var text = customData.text.toUpperCase();
                    }
                    var customizationData = {
                        'steps' : [
                            {
                                'step': customData.steps["1"].id,
                                'letter': "P",
                                'broderie': true,
                                'picture': {
                                    'src': customData.pictures.P,
                                    'width': customData.width,
                                    'height': customData.height,
                                },
                                'customization':  [
                                    {
                                        'product_id': currentProductStep.id,
                                        'color_id': currentProductStep.color_id,
                                        'size_id': currentProductStep.size_id,
                                        'position': {
                                            'x': reel_x,
                                            'y': reel_y,
                                        },
                                        'text': {
                                            'font': customData.font.list[customData.font.index].font,
                                            'font_name': (fontName) ? fontName : customData.font.list[customData.font.index].font,
                                            'color': customData.color.list[customData.color.index].code,
                                            'color_name': customData.color.list[customData.color.index].name,
                                            'size': 15.7,
                                            'size_number': 1,
                                            'value': text.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, ''), // SW-13560 Replace emoticones
                                            'location': location,
                                            'align': customData.position.list[customData.position.index].textAlign,
                                        },
                                        'rotation': 0,
                                        'price': currentProductStep.price,
                                        'ref': currentProductStep.ref,
                                        'refext': currentProductStep.refext,
                                        'sell_price': currentProductStep.sell_price,
                                        'title': currentProductStep.title,
                                    },
                                ]
                            }
                        ]
                    }

                    $('#embroidery_custom #button-overlay').addClass('loading');

                    $.ajax({
                        url: path_relative_root + create_link("ajax_configurator_save_customization.php"),
                        type: "post",
                        dataType: 'json',
                        data: {
                            product_id: productId,
                            customizationData: JSON.stringify(customizationData),
                            addToBasket: true,
                            basket_virtuel_id: res.panier_virtuel_id
                        },
                        success: function (res) {
                            try {
                                if (res.success) {
                                    $.ajax({
                                        url: path_relative_root + create_link("ajax_upd_panier.php"),
                                        type: "post",
                                        dataType: 'json',
                                        data: {
                                            configurator: true,
                                            idprod: currentProductStep.id,
                                            idcouleur: currentProductStep.color_id,
                                            idtaille: currentProductStep.size_id,
                                            panierid: 0,
                                            qte: 1,
                                            configurator_product: true,
                                            dataTypeReturn: 'json',
                                            reinit_basket_virtuel_parent_id: false,
                                        },
                                        success: function (res) {
                                            try {
                                                if (res.error == 0) {
                                                    window.location.href = path_relative_root + create_link('order_basket');
                                                }
                                            } catch (e) {
                                                console.error('JSON ERROR: could not parse data');
                                                return false;
                                            }
                                        }
                                    });
                                }

                            } catch (e) {
                                console.error('JSON ERROR: could not parse data');
                                return false;
                            }
                        }
                    });
                }
            } catch (e) {
                console.error('JSON ERROR: could not parse data');
                return false;
            }
        }
    });
}